<template>
  <h1>Browse Maps</h1>

  <div id="gallery-container">
    <div class="gallery-item" v-for="entry in database" :key="entry.id"> 
      <GalleryCard :entry="entry" />
    </div>
  </div>

</template>

<script>
import GalleryCard from '../components/GalleryCard'
export default {
  name: 'Gallery',
  components: {
    GalleryCard,
  },
  data() {
    return {
      database: [], // test database
    }
  },
  methods: {
    openEntry() {
      this.$router.push(
        {
          path: '/GalleryEntry',
        }
      )
    },
  },
  created() {
    this.database = [   // test database
        {
            "id": 0,
            "title": "Celestial Gate",
            "author": "u/Czepeku",
            "dimensions": "34x51",
            "filename": "celestial-gate.jpg",
            "tags": "encounter",
            "downloads": "420",
        },
        {
            "id": 1,
            "title": "Cirasia",
            "author": "u/cpt_PlanetNL",
            "dimensions": "",
            "filename": "cirasia.jpg",
            "tags": "city",
            "downloads": "69",
        },
        {
            "id": 2,
            "title": "Hags Hovel",
            "author": "u/mapguffin",
            "dimensions": "48x27",
            "filename": "hags-hovel.jpg",
            "tags": "encounter",
            "downloads": "420",
        },
        {
            "id": 3,
            "title": "Ancient Jungle Temple",
            "author": "u/ZeroGNexus",
            "dimensions": "60x70",
            "filename": "jungle-temple.jpg",
            "tags": "encounter",
            "downloads": "69",
        },
        {
            "id": 4,
            "title": "Mountain Arena",
            "author": "u/LoneMapper",
            "dimensions": "23x34",
            "filename": "mountain-arena.jpg",
            "tags": "encounter",
            "downloads": "420",
        },
        {
            "id": 5,
            "title": "Northern Star trapped in ice",
            "author": "u/RhasmusDND",
            "dimensions": "",
            "filename": "northern-star.jpg",
            "tags": "encounter",
            "downloads": "69",
        },
        {
            "id": 6,
            "title": "Orbea",
            "author": "u/MyCatTheNudist",
            "dimensions": "",
            "filename": "orbea.jpg",
            "tags": "city",
            "downloads": "420",
        },
        {
            "id": 7,
            "title": "Titanfall",
            "author": "u/dominikzdenkovic",
            "dimensions": "",
            "filename": "titanfall.jpg",
            "tags": "region",
            "downloads": "69",
        },
        {
            "id": 8,
            "title": "Vapore floating city",
            "author": "u/SallyMitchell",
            "dimensions": "",
            "filename": "vapore.jpg",
            "tags": "city",
            "downloads": "420",
        },
        {
            "id": 9,
            "title": "Final Decisions at the Volcano",
            "author": "u/AtaraxianBear",
            "dimensions": "50x36",
            "filename": "volcano.jpg",
            "tags": "encounter",
            "downloads": "69",
        },
        {
            "id": 10,
            "title": "Whiterun",
            "author": "u/Morpening",
            "dimensions": "",
            "filename": "whiterun.png",
            "tags": "city",
            "downloads": "420",
        },
        {
            "id": 11,
            "title": "Windfall",
            "author": "marcaroni3d <3",
            "dimensions": "",
            "filename": "windfall.jpg",
            "tags": "city",
            "downloads": "69",
        }
    ]
  }
}
</script>

<style scoped>

h1 {
  font-size: 32px;
  margin: 20px;
}
  #gallery-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  .gallery-item:hover {
    transform: scale(1.1);
    transition-duration: .3s;
  }
</style>
